const gSelector = {
	html: document.querySelector('html'),
	body: document.querySelector('body'),
};

// ユーザーエージェント判定
const deviceCategory = {
	mobile: "ua_mobile",
	tablet: "ua_tablet",
	pc: "ua_pc"
};
const deviceParser = () => {
	const ua = navigator.userAgent;
	if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
		// スマホ
		gSelector.html.classList.add('ua_mobile');
		return deviceCategory.mobile;
	} else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
		// タブレット
		gSelector.html.classList.add('ua_tablet');
		document.querySelector('meta[name=viewport]').setAttribute('content', 'width=1300,user-scalable=yes,maximum-scale=2.0');
		return deviceCategory.tablet;
	} else{
		// PC
		gSelector.html.classList.add('ua_pc');
		return deviceCategory.pc;
	}
};
const device = deviceParser();
// 例）スマホのときに処理したい場合
// if(device == deviceCategory.mobile) console.log("mobile");


// ウィンドウ関連の処理
(function(){	
	// 強制的にページの一番上へ
	const windowDefaultTop = setTimeout(()=>{
		const y = window.scrollY
		if (y > 0) {
			setTimeout(() => window.scrollTo(0, 0), 200)
		}
	}, 0);
	// ウィンドウサイズをcss変数に代入
	const windowSize = (() => {
		const windowSizeProperty = () => {
			document.documentElement.style.setProperty('--vh', (window.innerHeight) + 'px');
			document.documentElement.style.setProperty('--vw', (window.innerWidth) + 'px');
		};
		let timeoutID = 0;
		let delay = 500;
		windowSizeProperty();
		if(device == deviceCategory.pc || device == deviceCategory.tablet) {
			window.addEventListener("resize", () => {
				clearTimeout(timeoutID);
				timeoutID = setTimeout(()=>{
					windowSizeProperty();
				}, delay);
			}, false);
		} 
	})();
})();


// 要素が見えたらクラス追加
class scrollObserver {
	constructor(props) {
		const $this = this;
		$this.target = props.target; //対象の要素
		$this.options = props.options; // IntersectionObserverのオプション
		$this.activeClass = props.activeClass; // 付与するクラス名
		$this.observeEnd = props.observeEnd; // 要素が見えなくなった時にクラスを削除するか
		if(!$this.target) return;
		const array = Array.prototype.slice.call(document.querySelectorAll($this.target));
		const observer = new IntersectionObserver(ObserverCallback, $this.options)
		array.forEach((tgt) => {
			observer.observe(tgt)
		});
		function ObserverCallback(entries) {
			entries.forEach(function(entry, i) {
				const t = entry.target;
				if (entry.isIntersecting && !t.classList.contains($this.activeClass)) {
					t.classList.add($this.activeClass);
				} else {
					if($this.observeEnd == true)
					t.classList.remove($this.activeClass);
				}
			});
		};		
	}
}
const primaryObserver = new scrollObserver({
	target:'.js-view-observer',
	activeClass:'is-active',
	options:{
		root: null,
		rootMargin: "-50% 0px", 
		threshold: 0
	}
});
const hurryObserver = new scrollObserver({
	target:'.js-view-observer-hurry',
	activeClass:'is-active',
	options:{
		root: null,
		rootMargin: "-20% 0px", 
		threshold: 0
	}
});


// テキストアニメーション
(function(){
	const target = document.querySelectorAll('.js-text-fadein');
	if(!target) return;
	Array.from(target).forEach(function(e) {
		// spanで囲む
		const nodes = [...e.childNodes]; //要素をノードタイプで分割
		let spanWrapText = "";
		nodes.forEach((node) => {
			if (node.nodeType == 3) {//テキストの場合
				//テキストから改行コード削除
				const text = node.textContent.replace(/\r?\n/g, '');
				//spanで囲んで連結
				spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
					return acc + `<span class="js-text-fadein__text">${v}</span>`
				}, "");
			} else {//テキスト以外
				//<br>などテキスト以外の要素をそのまま連結
				spanWrapText = spanWrapText + node.outerHTML
			}
		});
		e.innerHTML = spanWrapText;
		// ディレイをつける
		Array.from(e.childNodes).forEach(function (a,i) {
			if (a.classList.contains('js-text-fadein__text')) {
				a.style.animationDelay = i*0.18+"s"
			}
		})
	});	
})();


// top kvアニメーション
(function(){
	// const mode = 'dev' // dev or prod;
	// if(mode == 'dev') { // devモードの時はfvアニメーションをオフにする
	// 	document.querySelector('.top-wrapper').classList.add('is-scroll-allow');
	// 	return;
	// };
	const topPage = document.querySelector('.page-top');
	if(!topPage) return;
	const topKvSet = {
		section : document.querySelector('.top-kv'),
		content : document.querySelector('.top-kv__inner'),
		logo : document.querySelector('.top-kv__heading__logo'),
		text : document.querySelector('.top-kv__heading__text'),
		object : document.querySelector('.top-kv__object'),
		activeClass : 'is-active',
	}
	//　スクロールをゆったりにする処理
	let niceScrollOptions = {
		cursorcolor:"#c7c7c7",
		cursorwidth:"10",
		cursorborderradius:"7px",
		background:"#FFF",
		cursorborder:"2px solid #FFF",
		cursoropacitymin:"1",
		hwacceleration:false
	};	
	const ua = window.navigator.userAgent.toLowerCase();
	if(ua.indexOf("windows nt") !== -1){
		niceScrollOptions.scrollspeed = 100;
	} else {
		niceScrollOptions.mousescrollstep = 9;
	}
	if(device == deviceCategory.pc) {
		$('body').niceScroll(niceScrollOptions);
	}
	// アニメーション
	topKvSet.content.addEventListener('animationend', function() {
		// ボックス全体
		topKvSet.content.classList.add(topKvSet.activeClass);
		// ロゴ
		topKvSet.content.addEventListener('transitionend', function() {
			topKvSet.logo.classList.add(topKvSet.activeClass);
		});	
		topKvSet.logo.addEventListener('transitionend', function() {
			// テキスト
			topKvSet.text.classList.add(topKvSet.activeClass);
			setTimeout(() => {
				// さんかく
				topKvSet.object.classList.add(topKvSet.activeClass);	
				// wrapperの高さ制限を解除するクラスを追加
				const topWrapper = document.querySelector('.top-wrapper');
				topWrapper.classList.add('is-scroll-allow');
				// 高さ制限を解除してからスクロール処理を再起動
				if(device == deviceCategory.pc) {
					$('body').getNiceScroll().resize();
				}
			}, 2000);		
		});	
	});	
})();


// top 写真のサイズ・位置調整
(function(){
	const target = document.querySelectorAll('.top-feature__image');
	if(!target) return;
	const topFeaturephPos = () => {
		Array.from(target).forEach(function(e) {
			const frame = e.querySelector('.top-feature__image__frame');
			const frameContent = e.querySelector('.top-feature__image__frame__content');
			let clientRect = e.getBoundingClientRect();
			let x = clientRect.left;
			let w = e.clientWidth;
			// let fixY1 = window.scrollX;
			// let fixY2 = frameContent.scrollX;
			// let fixY3 = fixY1 - fixY2;
			if(frameContent.classList.contains('js-top-feature-image-leftin')) {
				if (window.matchMedia( "(min-width: 768px)" ).matches) {
					w = e.clientWidth * 3.8;
				} else {
					w = e.clientWidth * 3;
				}
			}
			Object.assign(frameContent.style,{
				width:w + 'px',
				left:x + 'px',
				//top:fixY3 + 'px'
			});
		});	
	};
	topFeaturephPos();
	window.addEventListener("resize", () => {
		topFeaturephPos();
	});
	window.addEventListener("scroll", () => {
		topFeaturephPos();
	});
})();


// 図形アニメーション
(function(){
	if(!document.querySelector('.page-top')) return;
	// メイン箇所
	const featureBlock = document.querySelectorAll('.top-feature__block');
	featureBlock.forEach((target) => {
		const featureBlockTrigger = target.querySelector('.top-feature__image');
		// 最初は画像非表示で紫背景を見せる
		const featureFrameImage = target.querySelectorAll('.top-feature__image__frame__content img');
		featureFrameImage.forEach((e) => {
			gsap.to(e, {
				autoAlpha: 1,
				duration: 4, 
				scrollTrigger: {
					//markers:true,
					trigger: featureBlockTrigger,
					start: 'top+=10% center',
					//end: "bottom top",
				}
			}); 
		});
		// 画像を拡縮
		const featureFrame = target.querySelectorAll('.top-feature__image__frame__content:not(.js-top-feature-image-leftin)');
		featureFrame.forEach((e) => {
			gsap.to(e, {
				scale: 1.2,
				//transformOrigin:"80% 50%",
				duration: 14, 
				scrollTrigger: {
					//markers:true,
					trigger: featureBlockTrigger,
					toggleActions: 'restart reset restart reset',// onEnter onLeave onEnterBack onLeaveBack
					start: 'top+=10% center',
					//end: "bottom center",
					//scrub:.9,
				}
			}); 
		});
		// clip-path変形
		const featureBlockPath = target.querySelectorAll('.js-top-feature-path');
		featureBlockPath.forEach((e) => {
			let path1,path2;
			if(e.classList.contains('js-top-feature-path--reverse')) {
				path1 = "M5.499,134.624L0,1275.956c0,1.749,1.609,3.1,3.351,2.561l306.537-81.901c.937-.273,1.742-.945,1.875-2.022L589.853,3.292c.406-1.882-1.203-3.639-3.218-3.233L7.514,131.93c-1.078,.406-2.015,1.484-2.015,2.694Z";
				path2 = "M5.499,2.694L0,1276.026c0,1.749,1.609,3.1,3.351,2.561l578.537,.099c.937-.273,1.742-.945,1.875-2.022L589.853,3.362c.406-1.882-1.203-3.639-3.218-3.233L7.514,0c-1.078,.406-2.015,1.484-2.015,2.694Z";
			} else {
				path1 = "M280.069,1196.65l306.577,81.91c1.744,.41,3.354-.8,3.354-2.56l-5.501-1141.489c0-1.213-.805-2.291-2.012-2.56L3.278,.059C1.4-.346-.344,1.406,.058,3.292L278.191,1194.77c.268,.94,.939,1.61,1.878,1.88Z";
				path2 = "M1.9,1279l584.6-.1c1.7,.4,3.4-.8,3.4-2.6l.2-1273.7c0-1.2-.8-2.3-2-2.6L3.3,.1C1.4-.3-.3,1.4,.1,3.3l-.1,1273.8c.3,.9,1,1.6,1.9,1.9Z";
			}
			const keyframes = [
				{
					duration: 0.3,
					attr: { d: path1 }
				}, 
				{
					duration: 0.9,
					attr: { d: path1 }
				} , 
				{
					duration: 1,
					attr: { d: path2 }
				} 
			]
			gsap.timeline({
				scrollTrigger: {
					//markers:true,
					trigger: featureBlockTrigger, 
					start: "top center",
					end: "bottom+=50% center",
					scrub:true,
				}
			}).to(e, {
				keyframes: keyframes
			});
		});
		// 左右の動きを加える
		const gsapFeatureBlockPathEx = (from) => {
			const featureBlockPathEx = target.querySelectorAll('.js-top-feature-image-leftin');
			featureBlockPathEx.forEach((e) => {
				gsap.fromTo(e, {  
					x: () => (e.clientWidth*from),
				},
				{
					x: () => (e.clientWidth*(from/2.8)),
					duration: 60, 
					scrollTrigger: {
						//markers:true,
						toggleActions: 'restart reset restart reset',// onEnter onLeave onEnterBack onLeaveBack
						trigger: featureBlockTrigger,
						start: 'top+=20% center',
						//end: 'bottom+=15% bottom',
						//scrub:.9,
						invalidateOnRefresh: true,
					}
				});		
			});
		}
		ScrollTrigger.matchMedia({
			"(min-width: 768px)": function() {
				gsapFeatureBlockPathEx(-0.6);
			},
			"(max-width: 767px)": function() {
				gsapFeatureBlockPathEx(-0.66);
			}
		}); 
	});	
	// リサイズ時にScrollTriggerすべて再計算
	window.addEventListener("resize", () => {
		ScrollTrigger.refresh();
	});
})();


// モーダル
const onYouTubeIframeAPIReady = () => {
	const modal = document.querySelector('.modal');
	const modalCloseButtons = document.querySelectorAll('.js-modal-close');
	const modalOpenButtons = document.querySelectorAll('.js-modal-open');
	const modalVideos = document.querySelectorAll('.modal__movie__frame');
	let modalVideoId = null;
	// APIでのYouTube動画制御
  const videosId = Array.from(modalVideos).map(item => item.id);
  const players = videosId.map(id => {
    return new YT.Player(id, {
      videoId: id,
      playerVars: {
        loop: 0,
        rel: 0
      }
    });
  });
  // モーダル表示＆YouTube動画の自動再生
  modalOpenButtons.forEach(element => {
    element.addEventListener('click', (event) =>{
      modal.classList.add('is-active');
      const elementId = element.hash.replace('#', '');
      event.preventDefault();
      const targetVideo = players.filter(player => {
        return player.getVideoData().video_id === elementId;
      });
      document.getElementById(targetVideo[0].getVideoData().video_id).style.display = 'block';
      targetVideo[0].unMute().playVideo();
      modalVideoId = elementId;
			// body制御
			document.body.style.top = `-${window.scrollY}px`;		
			document.body.style.position = 'fixed';	
    });
  });
  // モーダルクローズ＆YouTube動画の停止
	Array.from(modalCloseButtons).forEach(function(e) {
		e.addEventListener("click", () => {
			const targetVideo = players.filter(player => {
				return player.getVideoData().video_id === modalVideoId;
			});
			targetVideo[0].stopVideo();
			document.getElementById(modalVideoId).style.display = 'none';
			modal.classList.remove('is-active');
			// body制御
			const resetPosition = parseInt(document.body.style.top) * -1;
			document.body.style.position = '';
			document.body.style.top = '';	
			window.scrollTo(0, resetPosition);	
		});
	});	
}